// 程序版本：V3.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：Tech@21gmail.com
// 单元名称：企业信息请求接口
// 开始时间：2021-4-7
// 开发人员：姚雨宏
// 最后修改：2021-4-7
// 备注说明：如需修改请联系开发人员
import {request} from "../request.js";
import qs from 'qs';
export default{
  //查询公司信息
findCompanyInfo(){
  return request({
      method:'POST',
      url:'/company/findCompanyInfo',
    })
  },
  //更新公司信息
updateCompanyInfo(data){
   return request({
       method:'POST',
       url:'/company/updateCompanyInfo',
       data,
     })
 },
 //查询企业授权参数
findxlicencefun(){
  return request({
      method:'POST',
      url:'/company/findxlicencefun',
    })
},
}
