<!-- 程序版本：V3.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：授权信息
开始时间：2021-3-24
开发人员：万攀
最后修改：2021-3-24
备注说明：如需修改请联系开发人员 -->

<template>
  <div class='ItemIndex_tabItem'>
    <div class="tabItem_contentBox billing_table">
      <el-table :data="inforMationData" height="100%" border style="width: 100%">
      <!-- 索引 -->
      <el-table-column type="index" fixed width="50"></el-table-column>
      <!-- 内容表格-->
      <el-table-column label="授权企业" width="120" show-overflow-tooltip>
        <template slot-scope="scope">
            <span class="license_company">{{scope.row.company_name}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="domain" label="授权域名"  width="120"></el-table-column>
      <el-table-column prop="max_user" label="用户数量"  width="120"></el-table-column>
      <el-table-column prop="start_date" label="开始日期"  width="120"></el-table-column>
      <el-table-column prop="end_date" label="结束日期"  min-width="12%"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
  import api from '@/network/datum/companyInfo'
  export default{
    data(){
      return{
       /* Qingdao Graceline International Co., Ltd. */
        inforMationData:[{
          company_name:'',
          domain:'',
          max_user:'',
          start_date:'',
          end_date:'',
        }]
      }
    },
    created() {
      this.findCompany();
       this.findxlicence();

    },
    methods:{
		findCompany(){
		  api.findCompanyInfo().then((res1)=>{

		    if(res1!=null){
         this.inforMationData[0].company_name=res1.data.company_name;
		    }

		  })
		},
      findxlicence(){
          api.findxlicencefun().then(res=>{

           this.inforMationData[0].domain=res.data[0].domain;
           this.inforMationData[0].max_user=res.data[0].max_user;
           this.inforMationData[0].start_date=res.data[0].start_date;
           this.inforMationData[0].end_date=res.data[0].end_date;

      })
      }
    }

  }
</script>

<style scoped="scoped" lang="less">
.ItemIndex_tabItem{
  width: 100%;
  height: 100%;
}
/* 文本超出长度显示省略号 */
.license_company{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
